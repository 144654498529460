import { Module } from "vuex";
import { IRootState } from "@monorepo/catalog/src/store";
import { QUERY_PATH } from "@monorepo/utils/src/api/queryPath";
import { mutations as baseMutations } from "@monorepo/utils/src/store/modules/mutations";
import { actions as baseActions } from "@monorepo/utils/src/store/modules/actions";
import { getters as baseGetters } from "@monorepo/utils/src/store/modules/getters";
import { cloneDeep } from "lodash";
import { IDictionaryElement } from "@monorepo/catalog/src/views/ServiceDirectories/types/IDictionaryElement";
import { getFullPath, getQuery } from "@monorepo/utils/src/api/utils";
import axios from "axios";
import { showNotification } from "@monorepo/utils/src/eventBus/utils/showNotification";
import { NOTIFICATION_STATUS } from "@monorepo/utils/src/eventBus/types/notification";

interface ITableState {
  data: IDictionaryElement[];
  localData: IDictionaryElement[];
  dictionariesItems: { [key: string]: IDictionaryElement[] };
  localDictionariesItems: { [key: string]: IDictionaryElement[] };
  infiniteId: string;
  isTableLoading: boolean;
}

export const module: Module<ITableState, IRootState> = {
  namespaced: true,
  state: (): ITableState => ({
    infiniteId: new Date().toString(),
    data: [],
    localData: [],
    dictionariesItems: {},
    localDictionariesItems: {},
    isTableLoading: false,
  }),
  mutations: {
    ...baseMutations,
    addData(
      state: ITableState,
      payload: {
        data: IDictionaryElement[];
      }
    ) {
      state.data = cloneDeep(payload.data);
      state.localData = cloneDeep(payload.data);
    },
    resetAllData(state: ITableState) {
      state.localData = cloneDeep(state.data);
      state.localDictionariesItems = cloneDeep(state.dictionariesItems);
    },
    clearAllItemsData(state: ITableState) {
      state.localDictionariesItems = {};
      state.dictionariesItems = {};
    },
    setAllData(state: ITableState) {
      state.data = cloneDeep(state.localData);
      state.dictionariesItems = cloneDeep(state.localDictionariesItems);
    },
    setLocalData(state: ITableState, payload: { data: IDictionaryElement[] }) {
      state.localData = cloneDeep(payload.data);
    },
    deleteDictionary(state: ITableState, guid: string) {
      const id = state.data.find((item) => item.guid === guid)?.id ?? "";
      state.data = state.data.filter((item) => item.guid !== guid);
      if (id) {
        delete state.localDictionariesItems[id];
        delete state.dictionariesItems[id];
      }
    },
    setGuidToDictionary(state: ITableState, { guid, id }: { [key: string]: string }) {
      state.localData = state.localData.map((item) => {
        if (item.id === id) {
          item.guid = guid;
        }
        return item;
      });
    },
    setDictionaryItem(state: ITableState, payload: { data: IDictionaryElement[]; key: string }) {
      state.dictionariesItems = {
        ...state.dictionariesItems,
        [payload.key]: cloneDeep(payload.data),
      };
      state.localDictionariesItems = {
        ...state.localDictionariesItems,
        [payload.key]: cloneDeep(payload.data),
      };
    },
    setLocalDictionaryItem(state: ITableState, payload: { data: IDictionaryElement[]; key: string }) {
      state.localDictionariesItems = {
        ...state.localDictionariesItems,
        [payload.key]: payload.data,
      };
    },
    changeDictVersion(state: ITableState, payload: { id: number; version: number }) {
      state.data = state.data.map((item) => {
        if (item.id === payload.id) {
          item.version = payload.version;
        }
        return item;
      });
      state.localData = state.localData.map((item) => {
        if (item.id === payload.id) {
          item.version = payload.version;
        }
        return item;
      });
    },
    setTableLoading(state: ITableState, payload: boolean) {
      state.isTableLoading = payload;
    },
  },
  actions: {
    ...baseActions,
    async getManualList({ commit, state, rootGetters }) {
      if (rootGetters["auth/isShowAnimation"]) {
        commit("setTableLoading", true);
      }
      const { data } = await getQuery<IDictionaryElement[]>(QUERY_PATH.GET_SERVICE_DICTIONARY_LIST).finally(() => {
        commit("setTableLoading", false);
      });
      if (data !== null) {
        commit("addData", { data: data || [] });
        return { data: state.data || [] };
      }
      return { data: null };
    },
    async getManualsItemsList(info, id) {
      const { data } = await getQuery<IDictionaryElement[]>(`${QUERY_PATH.GET_SERVICE_DICTIONARY_LIST}/${id}/items`);
      return data || [];
    },
    async importCsvFile(info, payload: { name: string; file: File; id: string | number }) {
      try {
        if (payload.id) {
          await info.dispatch("deleteDictionary", payload.id);
        }
        await axios.post(`${QUERY_PATH.GET_SERVICE_DICTIONARY_LIST}/import/${payload.name}`, payload.file, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      } catch (e) {
        console.error(e);
        showNotification("Ошибка импорта файла: " + e.response?.data?.message ?? "");
        return false;
      }
    },
    async saveDictionaryItems(info, payload: { items: IDictionaryElement[]; guid: string }) {
      try {
        await axios.post(getFullPath(`${QUERY_PATH.GET_SERVICE_DICTIONARY_LIST}/${payload.guid}/items`), payload.items);
        return true;
      } catch (e) {
        showNotification("Ошибка сохранения элементов справочника: " + e.response?.data?.message ?? "");
        return false;
      }
    },
    async changeDictVersion(info, payload: string) {
      try {
        const { data } = await axios.put(getFullPath(`${QUERY_PATH.GET_SERVICE_DICTIONARY_LIST}/version/${payload}`));
        if (data?.code === 0) {
          info.commit("changeDictVersion", data?.data ?? {});
          showNotification("Версия справочника успешно изменена", NOTIFICATION_STATUS.SUCCESS);
        } else {
          throw new Error(data);
        }

        return true;
      } catch (e) {
        showNotification("Ошибка изменения версии справочника: " + (e.response?.data?.message || ""));
        return false;
      }
    },
    async createDictionary(info, item: IDictionaryElement) {
      try {
        const { id, ...rest } = item;
        const response = await axios.post<{ data: string }>(getFullPath(`${QUERY_PATH.GET_SERVICE_DICTIONARY_LIST}`), rest);
        if (response.data?.data) {
          info.commit("setGuidToDictionary", { id, guid: response.data?.data });
        }
        return true;
      } catch (e) {
        console.error(e);
        showNotification("Ошибка сохранения справочника: " + e.response?.data?.message ?? "");
        return false;
      }
    },
    async deleteDictionary(info, id: string) {
      try {
        await axios.delete(getFullPath(`${QUERY_PATH.GET_SERVICE_DICTIONARY_LIST}/${id}`));
        info.commit("deleteDictionary", id);
        return true;
      } catch (e) {
        showNotification("Ошибка удаления справочника: " + e.response?.data?.message ?? "");
        return false;
      }
    },
  },
  getters: {
    ...baseGetters,
    data: (state: ITableState) => state.data ?? [],
    localData: (state: ITableState) => state.localData ?? [],
    dictionariesItems: (state: ITableState) => state.dictionariesItems ?? {},
    localDictionariesItems: (state: ITableState) => state.localDictionariesItems ?? {},
    isTableLoading(state: ITableState) {
      return state.isTableLoading;
    },
  },
};
